import React from 'react'
import Terms from '../components/Terms'
import Layout from '../components/Layout'
import Seo from '../components/Seo'

function terms() {
  return (
    <Layout border='2px solid #424242' lang='fr-fr'>
        <Seo title='Terms of use | Shukran' descriptionEn='Terms of use'  descriptionFr='Conditions d&apos;utilisation'/>
        <Terms fr={true}/>
    </Layout>
  )
}

export default terms